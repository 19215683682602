<template>
  <div class="content">
    <el-row type="flex" justify="end" class="marginTB20">
      <el-button @click="$router.go(-1)">返回</el-button>
    </el-row>
    <div class="searchWrapper paddingLR20 paddingT20">
      <el-form label-width="120px" :inline="true">
        <el-row :gutter="20">
          <el-col :span="7">
            <el-form-item>
              {{ "申诉ID: " + detailInfo.carappealId }}
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item>
              {{ detailInfo.authState | authStateFilter }}
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item>{{ "申诉时间: " + detailInfo.createdTime }}</el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="7">
            <el-form-item>
              {{ "手机号: " + detailInfo.mobile }}
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <!-- <el-form-item label="车牌颜色:">
          {{ detailInfo.plateColor | plateColorFileter }}
        </el-form-item> -->
            <el-form-item>
              {{ "车牌号: " + getPlate(detailInfo.plateColor, detailInfo.plateNumber) }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="7">
            <el-form-item>
              {{ "车主姓名: " + detailInfo.carOwnerName }}
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item>
              {{ "车辆识别号: " + detailInfo.frameNo }}
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item>
              {{ "发动机号码: " + detailInfo.engineNo }}
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-form-item v-if="detailInfo.authState !== 1" label="审核人:">{{
          authorName
        }}</el-form-item> -->
        <el-row :gutter="20" v-if="detailInfo.authState !== 1">
          <el-col>
            <el-form-item>{{ "审核时间: " + detailInfo.authTime }}</el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" v-if="detailInfo.authState === 3">
          <el-col>
            <el-form-item>
              {{ "拒绝原因: " + dealDescription }}
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-form :inline="true" label-position="top" style="width: 70%">
        <el-row type="flex" justify="left">
          <el-col :span="12" style="margin-right: 20px">
            <div class="pciTile">
              {{ "身份证（正面）:" }}
            </div>
            <div
              class="picBox"
              @click="showBigPic(picMap[1])"
              :style="{
                backgroundImage: 'url(' + picMap[1] + ')',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
              }"
            >
              <!-- <img
                :src="picMap[1]"
                width="60%"
                height="10%"
                @click="showBigPic(picMap[1])"
              /> -->
            </div>
          </el-col>
          <el-col :span="12" style="margin-right: 20px">
            <!-- <el-form-item label="身份证（正面）:"> -->
            <div class="pciTile">
              {{ "身份证（反面）:" }}
            </div>
            <!-- </el-form-item> -->
            <div
              class="picBox"
              @click="showBigPic(picMap[2])"
              :style="{
                backgroundImage: 'url(' + picMap[2] + ')',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
              }"
            >
              <!-- <div class="picBox"> -->
              <!-- <img
                :src="picMap[2]"
                width="60%"
                height="40%"
                @click="showBigPic(picMap[2])"
              /> -->
            </div>
          </el-col>
        </el-row>
        <el-row type="flex" justify="left">
          <el-col :span="12" style="margin-right: 20px">
            <!-- <el-form-item label="身份证（正面）:"> -->
            <div class="pciTile">
              {{ "行驶证（正面）:" }}
            </div>
            <!-- </el-form-item> -->
            <div
              class="picBox"
              @click="showBigPic(picMap[3])"
              :style="{
                backgroundImage: 'url(' + picMap[3] + ')',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
              }"
            >
              <!-- <img
                :src="picMap[3]"
                width="60%"
                height="40%"
                @click="showBigPic(picMap[3])"
              /> -->
            </div>
          </el-col>
          <el-col :span="12" style="margin-right: 20px">
            <!-- <el-form-item label="身份证（正面）:"> -->
            <div class="pciTile">
              {{ "行驶证（反面）:" }}
            </div>
            <!-- </el-form-item> -->
            <div
              class="picBox"
              @click="showBigPic(picMap[4])"
              :style="{
                backgroundImage: 'url(' + picMap[4] + ')',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
              }"
            >
              <!-- <img
                :src="picMap[4]"
                width="60%"
                height="40%"
                @click="showBigPic(picMap[4])"
              /> -->
            </div>
          </el-col>
        </el-row>
        <!-- </el-col>
        <el-col :span="12"> -->
        <!-- <el-row type="flex" justify="left">
          <el-form-item label="行驶证（正面）:">
            <img
              :src="picMap[3]"
              style="width: 90%"
              @click="showBigPic(picMap[3])"
            />
          </el-form-item>
          <el-form-item label="行驶证（反面）:">
            <img
              :src="picMap[4]"
              style="width: 90%"
              @click="showBigPic(picMap[4])"
            />
          </el-form-item>
        </el-row> -->
      </el-form>
      <!-- <el-form-item label="申诉理由:">{{ detailInfo.content }}</el-form-item> -->
      <!-- </el-col>
      </el-row> -->
      <!-- </el-form> -->
      <el-row type="flex" justify="center" class="marginTB20">
        <AuthorityComponent
          ComponentName="el-button"
          :permission="['subPage.view.button.approval']"
          v-if="detailInfo.authState === 1"
          type="success"
          @click="audit(2)"
          >通过</AuthorityComponent
        >
        <AuthorityComponent
          ComponentName="el-button"
          :permission="['subPage.view.button.approval']"
          v-if="detailInfo.authState === 1"
          type="danger"
          @click="resonDialogVisible = true"
          >拒绝</AuthorityComponent
        >
      </el-row>
    </div>
    <el-dialog title="拒绝原因" :visible.sync="resonDialogVisible">
      <!-- <el-form-item label="拒绝原因:"> -->
      <el-input
        :disabled="detailInfo.authState !== 1"
        type="textarea"
        :rows="2"
        placeholder="（必填）请输入拒绝原因"
        maxlength="100"
        show-word-limit
        v-model="dealDescription"
      />
      <!-- </el-form-item> -->
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button type="primary" @click="reject">确 定</el-button>
      </div>
    </el-dialog>
    <transition name="fade">
      <div
        class="mask"
        v-show="dialogTableVisible"
        :style="{ 'z-index': '10000', 'background-image': picUrl }"
        @click="consoleDetail"
      ></div>
    </transition>
  </div>
</template>

<script>
export default {
  filters: {
    authStateFilter(val) {
      if (!val) {
        return;
      }
      const result = ["", "待审核", "已通过", "已拒绝"];
      return "审核状态: " + result[val];
    },
    plateColorFileter(val) {
      const result = ["蓝色", "黄色", "绿色", "黄绿色", "黑色", "白色"];
      return result[val];
    },
  },
  data() {
    return {
      opinion: "",
      carappealId: this.$route.query.carappealId,
      detailInfo: {},
      picMap: {},
      dealDescription: "",
      authorName: "",
      picUrl: "",
      dialogTableVisible: false,
      resonDialogVisible: false,
    };
  },
  mounted() {
    this.getDetailInfo();
  },
  methods: {
    // 获取信息详情
    getDetailInfo() {
      const opt = {
        url: "/acb/2.0/carappeal/getCarappeal/" + this.carappealId,
        method: "get",
        data: {},
        success: (res) => {
          this.detailInfo = res.value.carappeal;
          this.picMap = res.value.picMap;
          this.dealDescription = this.detailInfo.dealDescription;
          this.authorName = res.value.authorName;
        },
      };
      this.$request(opt);
    },
    // 审核
    audit(authState) {
      // if (!this.dealDescription) {
      //   this.$alert("请输入审核意见", this.$t('pop_up.Tips'), {
      //     confirmButtonText: this.$t('pop_up.Determine'),
      //   });
      //   return;
      // }
      const opt = {
        url: "/acb/2.0/carappeal/verify",
        method: "post",
        data: {
          carAppealId: this.detailInfo.carappealId,
          author: sessionStorage.userId,
          authState: authState,
          content: this.dealDescription,
        },
        success: (res) => {
          this.$router.push("/complaintRecord");
        },
      };
      this.$request(opt);
    },
    getPlate(color, number) {
      const result = ["蓝：", "黄：", "绿：", "黄绿：", "黑：", "白："];
      return result[color] + number;
    },
    showBigPic(picUrl) {
      // this.getPic(dataRow.entryOprNum, dataRow.exitOprNum);
      this.picUrl = `url("${picUrl}")`;
      this.dialogTableVisible = true;
    },
    consoleDetail() {
      this.picUrl = "";
      this.dialogTableVisible = false;
    },
    reject() {
      if (!this.dealDescription || !this.dealDescription.trim()) {
        this.$alert("请输入拒绝原因", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      this.resonDialogVisible = false;
      this.audit(3);
    },
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.mask {
  background: rgba(49, 53, 65, 0.6) no-repeat center / contain;
  overflow: auto;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.picBox {
  box-sizing: border-box;
  text-align: left;
  width: 100%;
  height: 200px;
}

.pciTile {
  text-align: left;
  margin-top: 15px;
  margin-bottom: 15px;
}
</style>
